
import Vue from 'vue';

import { routes } from '@/entities/user';

import BaseAppBar from '@/entities/app/base-app-bar.vue';
import ProposalsSearch from '@/components/Campaigns/CampaignsTableFilters.vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'ProposalsAppBarWidget',

  inject: ['toggleDrawer'],

  components: {
    BaseAppBar,
    ProposalsSearch,
    C360Icon,
  },

  props: {
    filters: {
      type: Object,
    },
    searchTerm: {
      type: String,
    },
    skipStatusFilter: {
      type: Boolean,
      default: false,
    },
    createButtonTitle: {
      type: String,
      default: 'Create proposal',
    },
    customStatusFilterValues: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    routes() {
      return routes;
    },
  },
});
