
import Vue from 'vue';
import InstantIoTable from '@/widgets/instant-io/ui/table/instant-io-table.vue';
import { Services, Models } from '@/injectables/tokens';

import AppBar from '@/widgets/proposals/proposals-app-bar.vue';
import { debounce } from 'lodash';
import { InstantIOService, InstantIOServiceContract, RouterModelContract } from '@/injectables';
import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';
import { InstantCampaignStatus } from '../../app/graphql/_types';

export default Vue.extend({
  name: 'InstantIoList',

  useInjectable: [Services.InstantIO, Models.Router],

  components: { InstantIoTable, AppBar, ClientInfoDialog },

  data: (): {
    selectedClientId: string | null;
    page: number;
    campaigns;
    loading: boolean;
    total: number;
    duplicationLoading: boolean;
  } => ({
    selectedClientId: null,
    page: 1,
    campaigns: [],
    total: 0,
    loading: false,
    duplicationLoading: false,
  }),

  watch: {
    filters: {
      handler() {
        this.setFirstPage();
        this.getInstantIoList();
      },
      deep: true,
    },
    searchTerm() {
      this.setFirstPage();
      this.getInstantIoList();
    },
    '$route.query.modal': {
      handler(newVal) {
        if (!newVal) this.getInstantIoList();
      },
    },
  },

  beforeMount() {
    const routerEntity = this.$container.get(Models.Router);
    const queryKeys = Object.keys(this.$route.query);

    if (!queryKeys.includes('itemsPerPage')) this.itemsPerPage = 15;

    if (queryKeys.length !== 0 && !(queryKeys.length === 1 && queryKeys.includes('itemsPerPage'))) return;

    const sorting = (routerEntity as RouterModelContract).getDefaultProposalSorting();
    this.updateRoute({ sorting });
  },

  mounted(): void {
    this.getInstantIoList();
  },

  computed: {
    statusFilterValues(): { [key: string]: string } {
      return InstantCampaignStatus;
    },
    sorting() {
      const routerEntity = this.$container.get(Models.Router);
      const sorting = (routerEntity as RouterModelContract).getDefaultProposalSorting();
      const { sortBy, desc: sortDesc } = this.$route.query;

      const options = { ...sorting, sortBy, sortDesc };
      return {
        sortBy: options.sortBy,
        sortDesc: (routerEntity as RouterModelContract).stringToBoolean(options.sortDesc),
      };
    },
    options() {
      const { page, itemsPerPage } = this;
      return { ...this.sorting, page, itemsPerPage };
    },
    filters() {
      const routerEntity = this.$container.get(Models.Router);
      const { status, startFrom, startTo, endFrom, endTo, modifiedFrom, modifiedTo } = this.$route.query;

      return (routerEntity as RouterModelContract).packProposalFilters(status, {
        start: [startFrom, startTo],
        end: [endFrom, endTo],
        modified: [modifiedFrom, modifiedTo],
      });
    },
    searchTerm() {
      return this.$route.query.searchKey;
    },
    itemsPerPage: {
      get(): number {
        const routerEntity = this.$container.get(Models.Router);
        return (routerEntity as RouterModelContract).getValidItemPerPage(this.$route.query.itemsPerPage);
      },
      set(itemsPerPage: number) {
        this.updateRoute({ itemsPerPage });
      },
    },
    skippedRows() {
      return (this.page - 1) * this.itemsPerPage;
    },
  },

  methods: {
    async deleteInstantIo(id: string): Promise<void> {
      const { isErr } = await this[Services.InstantIO].deleteInstantIo(id);
      if (isErr()) {
        return;
      }

      this.getInstantIoList();
    },
    async duplicateInstantIo(id: string): Promise<void> {
      this.duplicationLoading = true;
      const { unwrap, isErr } = await (this[Services.InstantIO] as InstantIOServiceContract).duplicateInstantIo(id);
      this.duplicationLoading = false;

      if (isErr()) {
        return;
      }

      const { id: newId } = unwrap();
      this.openEditInstantIoDialog(newId);
      this.getInstantIoList();
    },
    openEditInstantIoDialog(id: string): void {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          modal: 'insertionOrders',
          id,
        },
      });
    },
    showCreateModal(): void {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          modal: 'insertionOrders',
        },
      });
    },
    updateFilters(filters) {
      this.updateRoute({ filters });
    },
    updateSearch(searchTerm) {
      this.updateRoute({ searchTerm });
    },
    updateRoute({
      filters = this.filters,
      sorting = this.sorting,
      searchTerm = this.searchTerm,
      itemsPerPage = this.itemsPerPage,
    }) {
      const query = (this.routerEntity as RouterModelContract).getProposalFilteringQuery(
        filters,
        sorting,
        searchTerm,
        itemsPerPage,
      );
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },

    closeClientInfoDialog() {
      this.selectedClientId = null;
      if (!this.$store.state.client.clients.loaded) this.getInstantIoList();
    },
    async openClientInfoDialog(id: string): Promise<void> {
      await this.$store.dispatch('client/updateActiveClientId', id);
      this.selectedClientId = id;
    },
    async changePage(page) {
      if (this.page === page) return;
      this.page = page;
      await this.getInstantIoList();
    },
    async changeCountOfItems(itemsPerPage) {
      if (this.itemsPerPage === itemsPerPage) return;
      this.itemsPerPage = itemsPerPage;
      await this.getInstantIoList();
    },
    async updateSort(sortBy) {
      if (this.sorting.sortBy === sortBy) return;
      this.updateRoute({ sorting: { ...this.sorting, sortBy } });
      await this.getInstantIoList();
    },
    async updateSortType(sortDesc) {
      if (this.sorting.sortDesc === sortDesc) return;
      this.updateRoute({ sorting: { ...this.sorting, sortDesc } });
      await this.getInstantIoList();
    },
    getInstantIoList: debounce(async function ({
      limit = this.itemsPerPage,
      offset = this.skippedRows,
      sortBy = this.sorting.sortBy,
      desc = this.sorting.sortDesc,
      searchTerm = this.searchTerm,
      filters = this.filters,
    } = {}) {
      this.loading = true;
      const { unwrap, isErr } = await this[Services.InstantIO].getInstantCampaigns({
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
      });

      if (isErr()) {
        this.campaigns = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      const { list, total } = unwrap();
      this.campaigns = list;
      this.total = total;
      this.loading = false;
      return;
    },
    300),
    setFirstPage() {
      this.options.page = 1;
    },
  },
});
